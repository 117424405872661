.watermark {
    display: none;
}

@media print {
    .watermark {
        display: block;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.05;
        z-index: 1000;
        font-size: 3em;
        width: 75%;
    }
}
